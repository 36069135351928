import React from "react";
import abotback from "../assets/back/aboutback.jpg";
import SectionTitle from "../component/Layout/SectionTitle";
import greeting from "../assets/icon/greeting.png";
import history from "../assets/icon/history.png";
import pin from "../assets/icon/pin.png";
import whatwedo from "../assets/icon/whatwedo.png";
import reference from "../assets/icon/reference.png";
import logo from "../assets/logo.eac8a157.svg";
import { BsTelephoneFill } from "react-icons/bs";
import { AiFillMail } from "react-icons/ai";
import IconBody from "../component/Layout/IconBody";
import ImageBox from "../component/ImageBox";
import Board from "../component/Board";
import GoogleMapReact from "google-map-react";
import { MdLocationOn } from "react-icons/md";

import { useMediaQuery } from "react-responsive";

const AboutUs = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  return (
    <div
      className="flex flex-col items-center justify-start min-h-screen"
      style={{
        backgroundImage: `linear-gradient(rgba(222, 222, 222, 0.1) 0%, rgb(0, 53, 63) 60%, rgb(0, 0, 0) 100%), url(${abotback})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        paddingTop: 150,
      }}
    >
      <div className="container mx-auto  px-6 xl:px-24 flex-col items-start ">
        <SectionTitle
          title="Welcome to the website"
          sub="Where Customer and EnCycle can meet with one accord"
        />
        <div className="grid  grid-cols-1 lg:grid-cols-2 gap-4 mt-12">
          <IconBody img={greeting} title="Greeting">
            <p className="text-justify text-white">
              EnCycle is an established testing organization for examining the
              quality of your requirements. We offer our services worldwide,
              with special technique and experience to the marine environment
              part. Our ISO/IEC 17025 certified laboratory provide fast, high
              accuracy service to our global client's demands. We have worked
              with several manufacturers, administrations, class societies and
              test laboratories in the past and we aim to deliver client
              satisfaction. We will become a company that puts the first on
              technology, environment and people for a sustainable future. Thank
              you for visiting our website
            </p>
          </IconBody>
          <IconBody img={history} title="Company History">
            <div className="text-white flex flex-row items-start justify-start">
              <p style={{ width: 65 }}>2021.05</p>
              <p>Commissioning / VGP test</p>
            </div>
            <div className="text-white flex flex-row items-start mt-1 justify-start">
              <p style={{ width: 65 }}>2021.04</p>
              <p className="flex-1">
                KOLAS accredited testing institute (No. KT942)
              </p>
            </div>
            <div className="text-white flex flex-row items-start mt-1 justify-start">
              <p style={{ width: 65 }}>2020.12</p>
              <p className="flex-1">
                Research institute company registration (No. 1086)
              </p>
            </div>
            <div className="text-white flex flex-row items-center mt-1 justify-start">
              <p style={{ width: 65 }}>2020.04</p>
              <p className="flex-1">Established EnCycle Co.,Ltd.</p>
            </div>
            <div className="text-white flex flex-row items-start mt-1 justify-start">
              <p style={{ width: 65 }}>2018.03</p>
              <p className="flex-1">Supply of test organism for IMO(G8)</p>
            </div>
            <div className="text-white flex flex-row items-start mt-1 justify-start">
              <p style={{ width: 65 }}>2015.10</p>
              <p className="flex-1">Supply of test organism for USCG</p>
            </div>
          </IconBody>
          <IconBody img={whatwedo} title="What we do">
            <p className="text-justify text-white">
              EnCycle is an established testing organization for examining the
              quality of your requirements. We offer our services
            </p>
            <div className="mt-3">
              <ImageBox col isTabletOrMobile={isTabletOrMobile} />
            </div>
          </IconBody>
          <IconBody img={pin} title="Visit Us">
            <div style={{ width: "100%", height: 400 }}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: process.env.REACT_APP_googlemap,
                  language: "en",
                  region: "en",
                }}
                defaultCenter={{
                  lat: 35.16074,
                  lng: 128.86114,
                }}
                defaultZoom={16}
              >
                <div
                  lat={35.16074}
                  lng={128.86114}
                  className="flex flex-col items-center justify-center"
                >
                  <MdLocationOn className="text-5xl text-red-500" />
                  <span className="font-bold text-xl">encycle</span>
                </div>
                {/* <AnyReactComponent
                  lat={59.955413}
                  lng={30.337904}
                  text="My Marker"
                /> */}
              </GoogleMapReact>
            </div>
            <div className="mt-12">
              <img
                src={logo}
                style={{ width: 140, height: 40, objectFit: "contain" }}
                alt="logo"
              />
              <div className="flex flex-row items-center text-white">
                <BsTelephoneFill />
                <p className="ml-3">+82-51-972-1830</p>
              </div>
              <div className="flex flex-row items-center text-white">
                <AiFillMail />
                <p className="ml-3">admin@encycle2020.com</p>
              </div>
            </div>

            <div className="text-white grid grid-cols-2 gap-4 mt-6">
              <div>
                <p className="font-bold">Office</p>
                <p>(46744)</p>
                <p>Rm211, 1537-1, Mieum-dong </p>
                <p>Gangseo-gu, Busan, South Korea.</p>
              </div>
              <div>
                <p className="font-bold">Laboratory</p>
                <p>(46744)</p>
                <p>Rm212, 1537-1, Mieum-dong </p>
                <p>Gangseo-gu, Busan, South Korea.</p>
              </div>
            </div>
          </IconBody>
        </div>
        <div className="mt-6">
          <IconBody img={reference} title="Reference">
            <Board
              filterArray={["ALL", "D-2", "VGP", "Test Organism"]}
              type="about"
            />
          </IconBody>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
