import React from "react";
import vgpback from "../assets/back/3.jpg";
import SectionTitle from "../component/Layout/SectionTitle";
import imo from "../assets/icon/imo.png";
import supply from "../assets/icon/supply.png";
import client from "../assets/icon/client.png";
import productmap from "../assets/back/productmap.png";
import procedure from "../assets/icon/procedure.png";
import IconBody from "../component/Layout/IconBody";
import { AiOutlineCaretRight } from "react-icons/ai";
import LineBar from "../component/Linebar";
import { useMediaQuery } from "react-responsive";
const Organism = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const procedureArray = [
    {
      title: "Natural Plankton",
      image: require("../assets/oranism/1.png").default,
      arrow: isTabletOrMobile ? true : true,
    },
    {
      title: "Collecting",
      image: require("../assets/oranism/2.png").default,
      arrow: isTabletOrMobile ? true : true,
    },

    {
      title: "Concentration",
      image: require("../assets/oranism/3.png").default,
      arrow: isTabletOrMobile ? false : true,
    },
    {
      title: "Mass storage",
      image: require("../assets/oranism/4.png").default,
      arrow: isTabletOrMobile ? true : true,
    },
    {
      title: "Quality check",
      image: require("../assets/oranism/5.png").default,
      arrow: isTabletOrMobile ? true : true,
    },
    {
      title: "Transporting",
      image: require("../assets/oranism/6.png").default,
      arrow: isTabletOrMobile ? false : false,
    },
  ];
  const clientArray = [
    { image: require("../assets/oranism/11.png").default },
    { image: require("../assets/oranism/22.png").default },
    { image: require("../assets/oranism/33.png").default },
    { image: require("../assets/oranism/44.png").default },
    { image: require("../assets/oranism/55.png").default },
    { image: require("../assets/oranism/66.png").default },
    { image: require("../assets/oranism/77.png").default },
    { image: require("../assets/oranism/88.png").default },
    { image: require("../assets/oranism/99.png").default },
    { image: require("../assets/oranism/1010.png").default },
  ];
  return (
    <div
      className="flex flex-col items-center justify-start min-h-screen"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 53, 63, 0.9) 0%, rgba(0, 53, 63, 0.9) 40%, rgba(0, 53, 63, 1) 60%, rgb(0, 0, 0) 100%), url(${vgpback})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        paddingTop: 150,
      }}
    >
      <div className="container mx-auto  px-6 xl:px-24 flex-col items-start ">
        <SectionTitle
          title="Test organism from Fresh, Brackish and Marine"
          sub="High concentration, High quality, Natural species"
        />
        <div className="mt-12 w-full flex flex-col lg:flex-row items-start">
          <div className="flex flex-col items-start w-full order-2 lg:order-1">
            <IconBody img={supply} title="Supply of Test Organisms">
              <div className="flex flex-row items-center">
                <LineBar />
                <p className="text-justify text-white ml-3">
                  Since 2015, Supply of Test Organisms (natural species) For
                  land-based test of BWMS.
                </p>
              </div>
              <div className="flex flex-row items-center mt-1">
                <LineBar />
                <p className="text-justify text-white ml-3">
                  Operating 6 or more locations for collection and concentration
                  supply annually (50 ~ 120 times/year)
                </p>
              </div>
              <div className="flex flex-row items-center mt-1">
                <LineBar />
                <p className="text-justify text-white ml-3">
                  EnCycle provide plankton of High concentration, high quality,
                  natural species.
                </p>
              </div>
              <div className="flex flex-row items-center mt-1">
                <LineBar />
                <p className="text-justify text-white ml-3">
                  Available in all water types of organisms (
                  <span className="text-green-500">Fresh</span>,{" "}
                  <span className="text-yellow-500">Brackish</span> and
                  <span className="text-blue-500"> Marine</span>)
                </p>
              </div>
              <div className="flex flex-row items-center mt-1">
                <LineBar />
                <p className="text-justify text-white ml-3">
                  High-density, mass storage and transportation all in one
                  service
                </p>
              </div>
            </IconBody>
            <div className="mt-12 w-full">
              <IconBody img={imo} title="Minimum Test Organisms Criteria">
                <table
                  className="text-xs lg:text-base text-white mt-3 w-full"
                  style={{ borderSpacing: "2px", borderCollapse: "separate" }}
                >
                  <thead className="w-full">
                    <tr className="bg-green-300 text-center ">
                      <th className="text-left  py-3 px-5  font-bold text-black">
                        Size Class
                      </th>
                      <th className="text-left  py-3 px-5  font-bold text-black">
                        Density
                      </th>
                      <th className="text-left  py-3 px-5 font-bold text-black">
                        Species diversity
                      </th>
                    </tr>
                  </thead>
                  <tbody className="w-full">
                    <tr className="">
                      <td className="bg-green-300 bg-opacity-20 py-3 px-5 text-left">
                        Organism ≥50 µm
                      </td>
                      <td className=" text-left py-3 px-5 bg-green-300 bg-opacity-20">
                        10<sup>5</sup> organisms / m<sup>3</sup>
                      </td>
                      <td className="text-left  py-3 px-5  bg-green-300 bg-opacity-20">
                        3 Phylum 5 Species
                      </td>
                    </tr>
                    <tr className="">
                      <td className="bg-green-300 bg-opacity-20 py-3 px-5 text-left">
                        Organism ≥10 µm to 〈50 µm
                      </td>
                      <td className=" text-left  py-3 px-5 bg-green-300 bg-opacity-20">
                        10<sup>3</sup> organisms / mL
                      </td>
                      <td className="text-left py-3 px-5  bg-green-300 bg-opacity-20">
                        3 Phylum 5 Species
                      </td>
                    </tr>
                    <tr className="">
                      <td className="bg-green-300 bg-opacity-20 py-3 px-5 text-left">
                        Origin
                      </td>
                      <td className=" text-left  py-3 px-5 bg-green-300 bg-opacity-20">
                        Natural species
                      </td>
                      <td className="text-left  py-3 px-5  bg-green-300 bg-opacity-20">
                        {null}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </IconBody>
            </div>
          </div>

          <img
            className="order-1 lg:order-2"
            src={productmap}
            style={{ width: 700, height: 300, objectFit: "contain" }}
            alt="locatoin"
          />
        </div>

        <div className="mt-12">
          <IconBody img={procedure} title="Procedure">
            <div className="text-white grid  grid-cols-3 lg:grid-cols-6 gap-12">
              {procedureArray.map((item, indx) => {
                return (
                  <div className="flex flex-col items-center">
                    <div className="flex flex-row items-center">
                      <img
                        src={item.image}
                        alt={item.title}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                      {item.arrow && (
                        <div>
                          <AiOutlineCaretRight className="text-3xl text-green-300 ml-2" />
                        </div>
                      )}
                    </div>
                    <span className="mt-3">{item.title}</span>
                  </div>
                );
              })}
            </div>
          </IconBody>
        </div>

        <div className="mt-12">
          <IconBody img={client} title="Client">
            <div className="text-white grid  grid-cols-4 lg:grid-cols-8 gap-12">
              {clientArray.map((item, indx) => {
                return (
                  <img
                    src={item.image}
                    alt={item.title}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                );
              })}
            </div>
          </IconBody>
        </div>
      </div>
    </div>
  );
};

export default Organism;
