import React from "react";
import ApplyBtn from "../ApplyBtn";

const SectionTitle = ({ title, sub }) => {
  return (
    <div>
      <h1 className="text-white text-2xl lg:text-4xl font-bold">{title}</h1>
      <p className="text-gray-300  text-xl lg:text-3xl  mt-3">{sub}</p>
      <ApplyBtn />
    </div>
  );
};

export default SectionTitle;
