import React, { Component } from "react";
import { useNavigate } from "react-router-dom";
const ApplyBtn = () => {
  const navigate = useNavigate();
  return (
    <button
      className="px-12 py-3 mt-6 text-xl text-black hover:bg-opacity-80 rounded-md bg-green-300"
      onClick={() => navigate("/apply")}
    >
      Apply
    </button>
  );
};

export default ApplyBtn;
