import React from "react";
import homeback from "../assets/back/homback.jpg";
import firstTap from "../assets/image/firstTap.jpg";
import secondTap from "../assets/image/secondTap.jpg";
import thirdTap from "../assets/image/thirdTap.jpg";
import fourthTap from "../assets/image/fourthTap.jpg";
import ImageBox from "../component/ImageBox";
import ApplyBtn from "../component/ApplyBtn";
const Home = () => {
  return (
    <div
      className="min-h-screen  flex flex-col  items-end  justify-end  w-full"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 70%, rgb(0, 0, 0) 100%), url(${homeback})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div
        className="w-full pt-24 py-12 px-12 xl:px-24 grid grid-cols-1 lg:grid-cols-2  container mx-auto 
        transition-all"
      >
        <div className="flex flex-col  justify-center  xl:p-0 xl:flex-col items-start flex-1 ">
          <h2 className="text-white lg:text-green-300 text-3xl xl:text-5xl font-bold">
            For Ballast Water Management Systems solution be with
          </h2>
          <p className="text-gray-300 text-2xl xl:text-4xl  mt-3">
            Contact the Encycle Lab Now
          </p>
          <ApplyBtn />
        </div>
        <ImageBox />
      </div>
    </div>
  );
};

export default Home;
