import React, { Component } from "react";
import firstTap from "../assets/image/firstTap.jpg";
import secondTap from "../assets/image/secondTap.jpg";
import thirdTap from "../assets/image/thirdTap.jpg";
import fourthTap from "../assets/image/fourthTap.jpg";

const ImageBox = ({ col, isTabletOrMobile }) => {
  return (
    <div
      className={`grid grid-cols-2 ${
        col ? "lg:grid-cols-2" : "lg:grid-cols-4"
      } mt-3 xl:mt-0`}
    >
      <div
        style={{
          width: "100%",
        }}
      >
        <a
          className="flex flex-col items-center  m-3 cursor-pointer overflow-hidden  justify-center  relative flex-1 rounded-xl "
          href="/commissioning"
        >
          <img
            className="transform transition-all scale-70 hover:scale-110 "
            src={firstTap}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
            alt="first"
          />
        </a>
        <p className="text-white text-xl  w-full py-1  text-center">
          Commissioning test
        </p>
      </div>
      <div style={{ width: "100%" }}>
        <a
          className="flex flex-col items-center  m-3 cursor-pointer overflow-hidden  justify-center  relative flex-1 rounded-xl "
          href="/vgp"
        >
          <img
            className="transform transition-all scale-70 hover:scale-110 "
            src={thirdTap}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
            alt="first"
          />
        </a>
        <p className="text-white text-xl  w-full py-1  text-center">VGP Test</p>
      </div>
      <div style={{ width: "100%" }}>
        <a
          className="flex flex-col items-center m-3 cursor-pointer overflow-hidden  justify-center  relative flex-1 rounded-xl "
          href="/organism"
        >
          <img
            className="transform transition-all scale-70 hover:scale-110 "
            src={fourthTap}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
            alt="first"
          />
        </a>
        <p className="text-white text-xl  w-full py-1  text-center">
          Test organism
        </p>
      </div>
      <div style={{ width: "100%" }}>
        <a
          className="flex flex-col items-center  m-3 cursor-pointer overflow-hidden  justify-center  relative flex-1 rounded-xl "
          href="/rnd"
        >
          <img
            className="transform transition-all scale-70 hover:scale-110 "
            src={secondTap}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
            alt="first"
          />
        </a>
        <p className="text-white text-xl  w-full py-1  text-center">R&D</p>
      </div>
    </div>
  );
};

export default ImageBox;
