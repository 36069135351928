import React from "react";
import commisionback from "../assets/back/commisionback.jpg";
import SectionTitle from "../component/Layout/SectionTitle";
import imo from "../assets/icon/imo.png";
import sample from "../assets/icon/sample.png";
import analysis from "../assets/icon/analysis.png";
import report from "../assets/icon/report.png";
import procedure from "../assets/icon/procedure.png";
import IconBody from "../component/Layout/IconBody";
import { AiOutlineCaretRight } from "react-icons/ai";
import { useMediaQuery } from "react-responsive";

const Commissioning = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const procedureArray = [
    {
      title: "Test request",
      image: require("../assets/comm/1.png").default,
      arrow: isTabletOrMobile ? true : true,
    },
    {
      title: "Visit vessels",
      image: require("../assets/comm/2.png").default,
      arrow: isTabletOrMobile ? true : true,
    },
    {
      title: "Ambient water",
      image: require("../assets/comm/3.png").default,
      arrow: isTabletOrMobile ? true : true,
    },
    {
      title: "Sampling port",
      image: require("../assets/comm/4.png").default,
      arrow: isTabletOrMobile ? false : true,
    },
    {
      title: "Self-monitoring",
      image: require("../assets/comm/5.png").default,
      arrow: isTabletOrMobile ? true : true,
    },
    {
      title: "Preparing",
      image: require("../assets/comm/6.png").default,
      arrow: isTabletOrMobile ? true : true,
    },
    {
      title: "Connection",
      image: require("../assets/comm/7.png").default,
      arrow: isTabletOrMobile ? true : true,
    },
    {
      title: "Sampling",
      image: require("../assets/comm/8.png").default,
      arrow: isTabletOrMobile ? false : false,
    },
    {
      title: "Collecting",
      image: require("../assets/comm/9.png").default,
      arrow: isTabletOrMobile ? true : true,
    },
    {
      title: "Samples",
      image: require("../assets/comm/10.png").default,
      arrow: isTabletOrMobile ? true : true,
    },
    {
      title: "Indicative analysis",
      image: require("../assets/comm/11.png").default,
      arrow: isTabletOrMobile ? true : true,
    },
    {
      title: "Detailed analysis",
      image: require("../assets/comm/12.png").default,
      arrow: isTabletOrMobile ? false : true,
    },
    {
      title: "Organism ≥50 µm",
      image: require("../assets/comm/13.png").default,
      arrow: isTabletOrMobile ? true : true,
    },
    {
      title: `Organism`,
      sub: "≥10 µm to 〈50 µm",
      image: require("../assets/comm/14.png").default,
      arrow: isTabletOrMobile ? true : true,
    },
    {
      title: "Final report",
      image: require("../assets/comm/15.png").default,
      arrow: isTabletOrMobile ? false : false,
    },
  ];
  return (
    <div
      className="flex flex-col items-center justify-start min-h-screen"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5) 0%, rgba(0, 53, 63, 0.9) 40%, rgba(0, 53, 63, 0.9) 60%, rgb(0, 0, 0) 100%), url(${commisionback})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        paddingTop: 150,
      }}
    >
      <div className="container mx-auto  px-6 xl:px-24 flex-col items-start ">
        <SectionTitle
          title="The Commissioning testing of Ballast Water Management Systems"
          sub="Validation of Biological Efficacy (Regulation D-2 by IMO)"
        />
        <div className="grid  grid-cols-1 lg:grid-cols-2 gap-4 mt-12">
          <IconBody img={imo} title="Commissioning Test by IMO">
            <p className="text-justify text-white">
              IMO adopted BWM.2/Circ.70/Rev.1 Guidance for the commissioning
              testing of BWMS which require an operational test including sample
              analysis to be conduct at the commissioning of the BWMS on board
              ships. A ship will now require to submit report of the
              commissioning testing to Flag (or Class) in order to complete the
              BWMS installation survey. Analysis methods, including indicative
              methods, listed in the guidance on ballast water sampling and
              analysis (BWM.2/Circ.42/Rev.2) may be applied. A test facility may
              use a portable test kit, given that the reliability and accuracy
              of this test kit has been validated. EnCycle are available to
              visit ship yards, dry docks where commissioning of BMWS are taking
              place. EnCycle's operators perform sampling, analysis and
              self-monitoring equipment to indicate correct operation. We will
              conduct analyses of samples for all size classes included in the
              D-2 standard using reliable and accurate (indicative and detailed)
              analysis methods. You will receive a final report in approximately
              24 - 48 hours. We invite you to prepare for validation of
              biological efficacy of the BWMS test. Our Lab team of expert
              engineers and biologists will help you with your compliance test.
            </p>
          </IconBody>
          <IconBody img={sample} title="Sampling">
            <p className="text-justify text-white">
              Sampling at discharge is conducted from the G2 isokinetic sampling
              port in the discharge line using sampling device. Organisms ≥50
              μm: Total sample volume should be at least 1 m<sup>3</sup>. using
              plankton net for concentrate the organisms. Organism ≥10 µm to
              〈50 µm and bacteria: the continuous drip sample. The flow is
              regulated during the whole process using flowmeter in order to
              achieve rates for isokinetic sampling according to system’s
              discharge rate. a sample may be collected during ballast water
              uptake to characterize the ambient water, by any means practical
              (e.g. in-line sample port or direct harbour sample).
            </p>
          </IconBody>
        </div>
        <div className="mt-6">
          <IconBody img={analysis} title="Analysis">
            <div className="text-white">
              <table className="text-xs lg:text-base  text-white mt-3">
                <thead>
                  <tr>
                    <td className="border-b-2 border-t-2 border-white  text-left  py-3 px-5 font-bold">
                      Test items
                    </td>
                    <td className="border-b-2  border-t-2 border-white    text-left  py-3 px-5  font-bold">
                      Indicative analysis
                    </td>
                    <td className="border-b-2  border-t-2 border-white    text-left  py-3 px-5  font-bold">
                      Detailed analysis
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr className=" border-b ">
                    <td className="text-left  py-3 px-5">Organism ≥50 µm</td>
                    <td className="text-left  py-3 px-5">ATP method</td>
                    <td className="text-left  py-3 px-5">
                      Stereo microscopy Examination(US EPA ETV Protocol, v. 5.1)
                    </td>
                  </tr>
                  <tr className=" border-b ">
                    <td className="text-left  py-3 px-5">
                      Organism ≥10 µm to 〈50 µm
                    </td>
                    <td className="text-left  py-3 px-5">ATP method</td>
                    <td className="text-left  py-3 px-5">
                      Visual counts with use of vital stains(US EPA ETV
                      Protocol, v. 5.1)
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </IconBody>
        </div>
        <div className="grid  grid-cols-1 lg:grid-cols-2 gap-4 mt-12">
          <IconBody img={report} title="Self-monitoring parameter">
            <p className="text-justify text-white">
              The applicable self-monitoring parameters of the BWMS should also
              be assessed, taking into account the system design limitations of
              the BWMS, and the correct operation of all sensors and related
              equipment should be confirmed (BWM.2/Circ.70/Rev.1.). Operators
              should be understand of the potential SDLs for the various types
              of technologies used in BWMS and the self-monitoring parameters
              that may be associated with these SDLs. EnCycle has procedures and
              knowledge to be able to assess the applicable self-monitoring
              parameters (e.g. flow rate, pressure, TRO, UV intensity, etc.) of
              the BWMS, taking into account the System Design Limitations of the
              BWMS.
            </p>
          </IconBody>
          <IconBody img={report} title="Report">
            <p className="text-justify text-white">
              The final report will be provided in a suitable format to submit
              to flag administrations or classification.
            </p>
            <div className="p-6 text-white">
              <p>- Indicative and Detailed analysis: 24 hours after sampling</p>
              <p>
                - If bacterial analysis is included: 48 hours after sampling
              </p>
            </div>
          </IconBody>
        </div>
        <div className="mt-12">
          <IconBody img={procedure} title="Procedure">
            <div className="text-white grid grid-cols-4 lg:grid-cols-8 gap-6">
              {procedureArray.map((item, indx) => {
                return (
                  <div className="flex flex-col items-center">
                    <div className="flex flex-row items-center">
                      <img
                        src={item.image}
                        alt={item.title}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                      {item.arrow && (
                        <div>
                          <AiOutlineCaretRight className="text-2xl text-green-300" />
                        </div>
                      )}
                    </div>
                    <span className="mt-3 text-sm text-center">
                      {item.title}
                    </span>
                    <span className="text-sm text-center">{item.sub}</span>
                  </div>
                );
              })}
            </div>
          </IconBody>
        </div>
      </div>
    </div>
  );
};

export default Commissioning;
