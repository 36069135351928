import React from "react";
import footback from "../assets/back/footerimage.jpg";
import { BsTelephoneFill } from "react-icons/bs";
import logo from "../assets/logo.eac8a157.svg";
import mark1 from "../assets/back/mark1.png";
import mark2 from "../assets/back/mark2.png";
const Footer = () => {
  return (
    <div
      className="bg-blue-900 max-w-screen overflow-hidden p-6 text-white"
      style={{
        backgroundImage: `linear-gradient(rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%), url(${footback})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="container mx-auto py-12 flex flex-col  items-center lg:items-end">
        <div className="flex flex-row items-center justify-end">
          <img
            src={logo}
            alt="logo"
            style={{ width: 140, height: 40, objectFit: "contain" }}
          />
          <img
            src={mark1}
            alt="logo"
            style={{ width: 80, height: 40, objectFit: "contain" }}
          />
          <img
            src={mark2}
            alt="logo"
            style={{ width: 80, height: 40, objectFit: "contain" }}
          />
        </div>
        <p className="mt-3 text-sm text-center">
          (46744) Rm211, Mieum, sandan 5-ro, 41beon-gil 21,Gangseo-gu, Busan,
          South Korea
        </p>
        <div className="flex flex-row items-center  mt-3">
          <BsTelephoneFill />
          <p className="text-sm ml-3">+82-51-972-1830</p>
        </div>
        <p className="mt-3 text-sm">
          Copyright © Encycle Co., Ltd. All rights reserved.
        </p>
        <a href="/admin" className="mt-3">
          <span className="bg-gray-400 px-6 py-1 mt-3 rounded-full">ADMIN</span>
        </a>
      </div>
    </div>
  );
};

export default Footer;
