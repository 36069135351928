import React from "react";
import IconTitle from "./IconTitle";

const IconBody = ({ img, title, children }) => {
  return (
    <div>
      <IconTitle img={img} title={title} />
      <div className="p-6">{children}</div>
    </div>
  );
};

export default IconBody;
