import * as React from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Footer from "./component/Footer";
import Header from "./component/Header";
import AboutUs from "./screen/AboutUs";
import Admin from "./screen/Admin";
import Apply from "./screen/Apply";
import Certifications from "./screen/Certifications";
import Commissioning from "./screen/Commissioning";
import DownLoad from "./screen/Download";
import Home from "./screen/Home";
import Organism from "./screen/Oranism";
import Rnd from "./screen/Rnd";
import Vgp from "./screen/Vgp";

function App() {
  return (
    <BrowserRouter>
      <div className="relative " style={{ backgroundColor: "rgb(0, 53, 63)" }}>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/commissioning" element={<Commissioning />} />
          <Route path="/vgp" element={<Vgp />} />
          <Route path="/organism" element={<Organism />} />
          <Route path="/rnd" element={<Rnd />} />
          <Route path="/certification" element={<Certifications />} />
          <Route path="/apply" element={<Apply />} />
          <Route path="/download" element={<DownLoad />} />
          <Route path="/admin" element={<Admin />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
