import React from "react";
import certiback from "../assets/back/certiback.jpg";
import SectionTitle from "../component/Layout/SectionTitle";
import certifications from "../assets/icon/certifications.png";
import IconBody from "../component/Layout/IconBody";
import Board from "../component/Board";

const Certifications = () => {
  return (
    <div
      className="flex flex-col items-center justify-start min-h-screen"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5) 0%, rgba(0, 53, 63, 0.9) 40%, rgba(0, 53, 63, 0.9) 60%, rgb(0, 0, 0) 100%), url(${certiback})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        paddingTop: 150,
      }}
    >
      <div className="container mx-auto  px-6 xl:px-24 flex-col items-start ">
        {/* <SectionTitle
          title="Research and Development"
          sub="Marine environment solution be with"
        /> */}
        <div className="mt-12">
          <IconBody img={certifications} title="Certifications">
            <Board
              filterArray={[
                "ALL",
                "ISO/IEC 17025",
                "Patents",
                "Service Suppliers",
              ]}
              type="cetification"
            />
          </IconBody>
        </div>
      </div>
    </div>
  );
};

export default Certifications;
