import React from "react";
import logo from "../assets/logo.eac8a157.svg";
import { useMediaQuery } from "react-responsive";
import HamMenu from "./HamMenu";

const Header = () => {
  const [height, setHeight] = React.useState(false);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const [scrollY, setScrollY] = React.useState(0);
  const listener = () => {
    console.log(window.pageYOffset);
    setScrollY(window.pageYOffset);
  };
  React.useEffect(() => {
    window.addEventListener("scroll", listener);
    return () => {
      window.removeEventListener("scroll", listener);
    };
  });
  return (
    <div
      className=" text-white fixed z-10 flex flex-col items-center w-full transition-all"
      style={{
        backgroundColor:
          scrollY > 50 ? "rgba(0, 53, 63, 1)" : "rgba(0, 53, 63, 0.8)",
      }}
    >
      <nav className="w-full lg:container lg:mx-auto flex flex-row items-start justify-between py-3 px-3 lg:px-0 relative">
        <a
          className="flex flex-col lg:flex-col items-start lg:items-start justify-between"
          href="/"
        >
          <img
            src={logo}
            alt="encycle logo"
            style={{ width: 200, height: 60, objectFit: "contain" }}
          />
          <p className="text-white">Marine environment research institute</p>
        </a>
        {isTabletOrMobile ? (
          <HamMenu />
        ) : (
          <ul
            className="flex flex-row items-start py-3 cursor-pointer  transition-all flex-1 px-12 justify-end mt-4"
            onMouseOver={() => setHeight(true)}
            onMouseOut={() => setHeight(false)}
          >
            <li
              className="flex flex-col items-start mr-3"
              style={{ width: 150 }}
            >
              <span className="font-bold text-xl text-white">About us</span>
              <div
                className={`mt-3 flex flex-col items-start  justify-start transition-all ${
                  height ? "visible opacity-100" : "invisible opacity-0"
                }`}
                style={{ height: height ? 100 : 0 }}
              >
                <a
                  className=" text-base text-green-600 hover:text-white"
                  href="/about"
                >
                  About us
                </a>
              </div>
            </li>
            <li
              className="flex flex-col items-start mr-3"
              style={{ width: 150 }}
            >
              <span className="font-bold text-xl text-white">Services</span>
              <div
                className={`mt-3 flex flex-col items-start  justify-start transition-all ${
                  height ? "visible opacity-100" : "invisible opacity-0"
                }`}
                style={{ height: height ? 100 : 0 }}
              >
                <a
                  className=" text-base text-green-600 hover:text-white"
                  href="/commissioning"
                >
                  Commissioning test
                </a>
                <a
                  className=" text-base text-green-600 hover:text-white"
                  href="/vgp"
                >
                  VGP test by USCG
                </a>
              </div>
            </li>
            <li
              className="flex flex-col items-start mr-3"
              style={{ width: 150 }}
            >
              <span className="font-bold text-xl text-white">Product</span>
              <div
                className={`mt-3 flex flex-col items-start  justify-start transition-all ${
                  height ? "visible opacity-100" : "invisible opacity-0"
                }`}
                style={{ height: height ? 100 : 0 }}
              >
                <a
                  className=" text-base text-green-600 hover:text-white"
                  href="/organism"
                >
                  Test Organism
                </a>
              </div>
            </li>
            <li
              className="flex flex-col items-start justify-start mr-3"
              style={{ width: 120 }}
            >
              <span className="font-bold text-xl text-white">R & D</span>
              <div
                className={`mt-3 flex flex-col items-start  justify-start transition-all ${
                  height ? "visible opacity-100" : "invisible opacity-0"
                }`}
                style={{ height: height ? 100 : 0 }}
              >
                <a
                  className=" text-base text-green-600 hover:text-white"
                  href="/rnd"
                >
                  R & D
                </a>
              </div>
            </li>
            <li
              className="flex flex-col items-start mr-3"
              style={{ width: 150 }}
            >
              <span className="font-bold text-xl text-white">
                Certification
              </span>
              <div
                className={`mt-3 flex flex-col items-start  justify-start transition-all ${
                  height ? "visible opacity-100" : "invisible opacity-0"
                }`}
                style={{ height: height ? 100 : 0 }}
              >
                <a
                  className=" text-base text-green-600 hover:text-white"
                  href="/certification"
                >
                  Certification
                </a>
              </div>
            </li>
            <li
              className="flex flex-col items-start mr-3"
              style={{ width: 150 }}
            >
              <span className="font-bold text-xl text-white">Contact us</span>
              <div
                className={`mt-3 flex flex-col items-start  justify-start transition-all ${
                  height ? "visible opacity-100" : "invisible opacity-0"
                }`}
                style={{ height: height ? 100 : 0 }}
              >
                <a
                  className=" text-base text-green-600 hover:text-white"
                  href="/apply"
                >
                  Apply
                </a>
                <a
                  className=" text-base text-green-600 hover:text-white"
                  href="/download"
                >
                  Download
                </a>
              </div>
            </li>
          </ul>
        )}
      </nav>
    </div>
  );
};

export default Header;
