import React from "react";
import apply from "../assets/back/apply.jpg";
import SectionTitle from "../component/Layout/SectionTitle";
import certifications from "../assets/icon/certifications.png";
import IconBody from "../component/Layout/IconBody";
import emailjs from "emailjs-com";
const Apply = () => {
  const [email, setEmail] = React.useState();
  const [name, setName] = React.useState();
  const [message, setMessage] = React.useState();

  const sendEmail = () => {
    console.log(process.env.REACT_APP_emailjs);
    console.log("test");
    if (email && name && message) {
      emailjs
        .send(
          "service_ap7flvk",
          "template_0mq26i6",
          {
            reply_to: email,
            to_name: "encycle",
            from_name: name,
            message: message,
          },
          process.env.REACT_APP_emailjs
        )
        .then(
          result => {
            console.log(result.text);
            setEmail();
            setName();
            setMessage();
            alert("send message successful");
            window.location.reload();
          },
          error => {
            console.log(error.text);
          }
        );
    }
  };
  return (
    <div
      className="flex flex-col items-center justify-start min-h-screen"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5) 0%, rgba(0, 53, 63, 0.9) 40%, rgba(0, 53, 63, 0.9) 60%, rgb(0, 0, 0) 100%), url(${apply})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        paddingTop: 150,
      }}
    >
      <div className="container mx-auto  px-6 xl:px-24 flex flex-col items-center ">
        <div className="mt-12 flex flex-col items-center w-1/2 ">
          <IconBody img={certifications} title="APPLY">
            <div className="text-white">
              <div className="bg-black bg-opacity-40 flex flex-col items-start p-6 ">
                <p>Please write us a message if you have any questions.</p>
                <p>We will answer you as soon as possible. Thank you!</p>
                <div className="flex flex-col items-start w-full mt-6">
                  <span>EMAIL</span>
                  <input
                    type="text"
                    className="px-6 py-3 rounded-lg mt-3 w-full text-black"
                    onChange={e => setEmail(e.target.value)}
                  />
                </div>
                <div className="flex flex-col items-start w-full mt-3">
                  <span>NAME</span>
                  <input
                    type="text"
                    className="px-6 py-3 rounded-lg mt-3 w-full text-black"
                    onChange={e => setName(e.target.value)}
                  />
                </div>
                <div className="flex flex-col items-start w-full mt-3">
                  <span>MESSAGE</span>
                  <textarea
                    style={{ width: "100%", height: 200 }}
                    type="text"
                    className="px-6 py-3 rounded-lg mt-3 w-full text-black"
                    onChange={e => setMessage(e.target.value)}
                  />
                </div>

                <button
                  className="bg-green-300 text-black px-6 py-3 w-full rounded-xl mt-6"
                  onClick={() => sendEmail()}
                >
                  SEND
                </button>
              </div>
            </div>
          </IconBody>
        </div>
      </div>
    </div>
  );
};

export default Apply;
