import React from "react";
import Modal from "react-modal";
import { AiFillCloseCircle } from "react-icons/ai";
import Loader from "react-loader-spinner";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db, storage } from "../../firebaseInstance";
import Board from "../Board";
import BoardWithDelete from "./BoardWithDelete";

const VgpUploader = () => {
  const [filter, setFilter] = React.useState([
    "Chlorine based",
    "Chlorine dioxide based",
    "UV type",
    "Ozone type",
  ]);
  const [type, setType] = React.useState("Chlorine based");
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = React.useState();
  const [percent, setPercent] = React.useState(0);

  const clickUpload = async () => {
    if (file && type) {
      console.log(file.file);
      const metadata = {
        contentType: file.file.type,
      };
      const storageRef = ref(storage, `vgp/${file.file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file.file, metadata);
      uploadTask.on(
        "state_changed",
        snapshot => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setPercent(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
          }
        },
        error => {
          // Handle unsuccessful uploads
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then(async downloadURL => {
            console.log("File available at", downloadURL);
            // write db
            await addDoc(collection(db, "vgp"), {
              createAt: serverTimestamp(),
              file: {
                downloadUrl: downloadURL,
                filename: file.file.name,
                filesize: file.file.size,
              },
              type: type,
            }).then(result => {
              setOpen(false);
              setFile();
              setType();
              setPercent(0);
              alert("업로드가 완료 되었습니다.");
              window.location.reload();
            });
          });
        }
      );
    }
  };

  const handleImageChange = e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    console.log(file, reader);
    reader.onloadend = () => {
      setFile({ file: file, previewURL: reader.result });
    };
    reader.readAsDataURL(file);
  };

  return (
    <div className="border-t w-full mt-3">
      <button
        onClick={() => setOpen(true)}
        className={`px-12 py-3 text-black mr-3 bg-gray-200 mt-6`}
      >
        업로드
      </button>
      <div>
        <BoardWithDelete
          filterArray={[
            "ALL",
            "Chlorine based",
            "Chlorine dioxide based",
            "UV type",
            "Ozone type",
          ]}
          type="vgp"
        />
      </div>
      <Modal
        shouldCloseOnOverlayClick={false}
        style={{
          overlay: {
            backgroundColor: "rgba(0,0,0,0.8)",
            zIndex: 3000,
          },
          content: {
            width: "50%",
            top: "30%",
            left: "50%",

            transform: "translate(-50%, -30%)",
          },
        }}
        isOpen={open}
        onRequestClose={() => setOpen(!open)}
      >
        <div className="flex flex-row items-center justify-between relative w-full border-b pb-2">
          <h2 className="text-3xl font-bold">About us 업로드</h2>
          <button onClick={() => setOpen(false)} className="">
            <AiFillCloseCircle className="text-3xl" />
          </button>
        </div>
        <div className="flex flex-col items-start mt-3 border-b pb-6">
          <p className="text-xl">타입을선택하세요.</p>
          <select
            className="border px-3 py-3 mt-3"
            value={type}
            onChange={e => setType(e.target.value)}
          >
            {filter.map((item, index) => {
              return (
                <option key={index} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
        </div>
        <div className="flex flex-col items-start mt-3  border-b pb-6">
          <p className="text-xl">이미지파일을 선택하세요</p>
          <input
            type="file"
            className="mt-3"
            accept="image/jpg,impge/png,image/jpeg,image/gif"
            onChange={e => handleImageChange(e)}
          />
          <div style={{ width: 300, height: 300 }} className="mt-3">
            {file && (
              <img
                className="profile_preview"
                src={file.previewURL}
                alt="preview"
                style={{ width: 300, height: 300, objectFit: "contain" }}
              />
            )}
          </div>
        </div>
        <div className="mt-6">
          <div className="w-full bg-gray-400" style={{ height: 4 }}>
            <div
              className="bg-green-300"
              style={{ height: 3, width: `${percent}%` }}
            />
          </div>
          <button
            className="bg-green-300 px-12 py-3 mt-6 flex flex-row items-center"
            onClick={() => clickUpload()}
          >
            {percent > 0 ? (
              <Loader type="TailSpin" color="white" height={30} width={30} />
            ) : (
              <p className="ml-3">업로드</p>
            )}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default VgpUploader;
