import React, { Component } from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { collection, query, getDocs } from "firebase/firestore";
import { db } from "../firebaseInstance";
const data = ["1", "2", "3", "4", "5"];

const Board = ({ filterArray, type }) => {
  const [page, setPage] = React.useState(1);
  const [pageCount, setCount] = React.useState(5);
  const [filter, setFilter] = React.useState("ALL");
  const [queryData, setQueryData] = React.useState([]);
  const [finalData, setFinalData] = React.useState([]);

  const getDb = async () => {
    const q = query(collection(db, type));
    const querySnapshot = await getDocs(q);
    const dbArray = [];
    querySnapshot.forEach(doc => {
      console.log(doc.id, " => ", doc.data());
      dbArray.push({ ...doc.data() });
    });

    if (filter === "ALL") {
      setQueryData(dbArray);
    } else {
      let newArray = dbArray.filter(item => item.type === filter);
      setQueryData(newArray);
    }
  };
  React.useEffect(() => {
    // query
    getDb();
  }, [filter]);

  React.useEffect(() => {
    // query
    setCount(Math.ceil(queryData.length / 5));
    if (queryData.length > 5) {
      if (page === 1) {
        let newdata = queryData.slice(5 * page - 5, page * 5);
        setFinalData(newdata);
      } else {
        let newdata = queryData.slice(5 * page - 5, page * 5);
        setFinalData(newdata);
      }
    } else {
      setFinalData(queryData);
    }
    console.log(queryData);
  }, [page, queryData]);

  return (
    <div className="text-white w-full flex flex-col items-start">
      {filterArray.length > 0 && (
        <div className="flex flex-row items-center self-end">
          {filterArray.map((item, index) => {
            return (
              <div key={index} className={`mr-6`}>
                <button
                  onClick={() => setFilter(item)}
                  className={` pb-2 ${
                    filter === item
                      ? "border-b-2 border-green-300"
                      : "border-b-2 border-gray-300"
                  }`}
                >
                  {item}
                </button>
              </div>
            );
          })}
        </div>
      )}
      {finalData.length > 0 ? (
        <React.Fragment>
          <div className="grid grid-cols-1 lg:grid-cols-5 gap-4 w-full mt-12">
            {finalData.map((item, index) => {
              return (
                <div
                  className="col-span-1 bg-gray-100 rounded-lg overflow-hidden"
                  style={{ minHeight: 300 }}
                  key={index}
                >
                  <img
                    src={item.file.downloadUrl}
                    alt=""
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
              );
            })}
          </div>
          {queryData.length > 5 && (
            <div className="flex flex-row items-center self-center mt-12">
              <div
                className={`m-1 bg-gray-400 flex flex-col items-center justify-center`}
                style={{ width: 40, height: 40 }}
                onClick={() => {
                  if (page > 1) {
                    setPage(page - 1);
                  }
                }}
              >
                <AiOutlineLeft />
              </div>
              {new Array(Math.ceil(queryData.length / 5))
                .fill(0)
                .map((item, index) => {
                  return (
                    <button
                      key={index}
                      className={`m-1 ${
                        page === index + 1 ? "bg-green-300" : "bg-gray-400"
                      }`}
                      onClick={() => setPage(index + 1)}
                      style={{ width: 40, height: 40 }}
                    >
                      <p
                        className={`${
                          page === index + 1 ? "text-black" : "text-white"
                        }`}
                      >
                        {index + 1}
                      </p>
                    </button>
                  );
                })}
              <div
                onClick={() => {
                  if (page < pageCount) {
                    setPage(page + 1);
                  }
                }}
                className={`m-1 bg-gray-400 flex flex-col items-center justify-center`}
                style={{ width: 40, height: 40 }}
              >
                <AiOutlineRight />
              </div>
            </div>
          )}
        </React.Fragment>
      ) : (
        <div>
          <p>Empty data</p>
        </div>
      )}
    </div>
  );
};

export default Board;
