import React, { Component } from "react";
import AboutUploader from "../component/uploader/AboutUploader";
import CertificationUploader from "../component/uploader/CertificationUploader";
import DownUploader from "../component/uploader/DownUploader";
import RndUploader from "../component/uploader/RndUploader";
import VgpUploader from "../component/uploader/VgpUploader";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

const Admin = () => {
  const auth = getAuth();
  const [type, setType] = React.useState("about");
  const [user, setUser] = React.useState();
  const [email, setEmail] = React.useState();
  const [password, setPassword] = React.useState();
  React.useEffect(() => {
    onAuthStateChanged(auth, user => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        console.log(user);
        setUser(user);
        // ...
      } else {
        // User is signed out
        // ...
        setUser();
      }
    });
  }, []);
  const clickLogin = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then(userCredential => {
        // Signed in
        const user = userCredential.user;
        // ...
        setUser(user);
      })
      .catch(error => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorMessage);
      });
  };
  const clickLogout = () => {
    if (window.confirm("정말 로그아웃 하시겠습니까?")) {
      signOut(auth)
        .then(() => {
          // Signed in

          setUser();
        })
        .catch(error => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorMessage);
        });
    }
  };
  return (
    <React.Fragment>
      {user ? (
        <div
          className="flex flex-col items-start justify-start min-h-screen text-white container mx-auto"
          style={{ paddingTop: 150 }}
        >
          <div className="flex flex-row items-center">
            <p className="text-2xl font-bold">Admin</p>

            <button
              onClick={() => clickLogout()}
              className="ml-3 bg-gray-200 px-3 text-black"
            >
              로그아웃
            </button>
          </div>
          <p className="mt-3">각 메뉴의 사진게시판을 관리하세요.</p>
          <div className="flex flex-row items-center mt-3">
            <button
              onClick={() => setType("about")}
              className={`${
                type === "about" ? "bg-green-300" : "bg-gray-400"
              } px-12 py-3 text-black mr-3`}
            >
              About us
            </button>
            <button
              onClick={() => setType("vgp")}
              className={`${
                type === "vgp" ? "bg-green-300" : "bg-gray-400"
              } px-12 py-3 text-black mr-3`}
            >
              Services - VGP test by USCG
            </button>
            <button
              onClick={() => setType("rnd")}
              className={`${
                type === "rnd" ? "bg-green-300" : "bg-gray-400"
              } px-12 py-3 text-black mr-3`}
            >
              R&D
            </button>
            <button
              onClick={() => setType("certification")}
              className={`${
                type === "certification" ? "bg-green-300" : "bg-gray-400"
              } px-12 py-3 text-black mr-3`}
            >
              Certification
            </button>
            <button
              onClick={() => setType("download")}
              className={`${
                type === "download" ? "bg-green-300" : "bg-gray-400"
              } px-12 py-3 text-black mr-3`}
            >
              Download
            </button>
          </div>
          <div className="w-full">{type === "about" && <AboutUploader />}</div>
          <div className="w-full">{type === "vgp" && <VgpUploader />}</div>
          <div className="w-full">{type === "rnd" && <RndUploader />}</div>
          <div className="w-full">
            {type === "certification" && <CertificationUploader />}
          </div>
          <div className="w-full">
            {type === "download" && <DownUploader />}
          </div>
        </div>
      ) : (
        <div
          className="flex flex-col items-center justify-center min-h-screen text-white container mx-auto"
          style={{ paddingTop: 150 }}
        >
          <div className="bg-black bg-opacity-50 w-1/2 flex flex-col items-center justify-center p-6">
            <p>ADMIN 로그인</p>
            <div className="w-full">
              <p>EMAIL</p>
              <input
                type="text"
                onChange={e => setEmail(e.target.value)}
                className="text-black px-3 py-3 w-full"
              />
            </div>
            <div className="w-full mt-6">
              <p>PASSWORD</p>
              <input
                type="password"
                onChange={e => setPassword(e.target.value)}
                className="text-black px-3 py-3 w-full"
              />
            </div>

            <button
              onClick={() => clickLogin()}
              className="mt-6 bg-green-300 w-full px-3 py-3 text-black"
            >
              로그인
            </button>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Admin;
