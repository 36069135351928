import React from "react";
import vgpback from "../assets/back/vgpback.jpg";
import SectionTitle from "../component/Layout/SectionTitle";
import imo from "../assets/icon/imo.png";
import sample from "../assets/icon/sample.png";
import analysis from "../assets/icon/analysis.png";
import report from "../assets/icon/report.png";
import procedure from "../assets/icon/procedure.png";

import history from "../assets/icon/history.png";
import pin from "../assets/icon/pin.png";
import whatwedo from "../assets/icon/whatwedo.png";
import reference from "../assets/icon/reference.png";
import logo from "../assets/logo.eac8a157.svg";
import { BsTelephoneFill } from "react-icons/bs";
import { AiFillMail } from "react-icons/ai";
import IconBody from "../component/Layout/IconBody";
import ImageBox from "../component/ImageBox";
import Board from "../component/Board";

const Vgp = () => {
  return (
    <div
      className="flex flex-col items-center justify-start min-h-screen"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5) 0%, rgba(0, 53, 63, 0.9) 40%, rgba(0, 53, 63, 0.9) 60%, rgb(0, 0, 0) 100%), url(${vgpback})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        paddingTop: 150,
      }}
    >
      <div className="container mx-auto  px-6 xl:px-24 flex-col items-start ">
        <SectionTitle
          title="Biological compliance monitoring of ballast water for VGP"
          sub="Biocides and Residuals Monitoring"
        />
        <div className="mt-12 w-full">
          <IconBody img={imo} title="VGP test of Ballast Water by USCG">
            <p className="text-justify text-white">
              Vessels sailing on the United States of America have to comply
              with the regulations for the 2013 Vessel General Permit (VGP).
              Besides ballast water, VGP also requires sampling and analyses for
              bilge water, gray water and scrubber wash water. Those analyses
              can also be conducted in consultation. We provide rapid,
              independent and scientific sampling and testing services to help
              vessels comply with US EPA VGP 2013.
            </p>
          </IconBody>
          <table
            className="text-xs lg:text-base text-white mt-12 w-full"
            style={{ borderSpacing: "2px", borderCollapse: "separate" }}
          >
            <thead className="w-full">
              <tr className="bg-green-300 text-center ">
                <th
                  colspan="2"
                  className="text-center  py-3 px-5 lg:px-12 font-bold"
                >
                  Test items
                </th>
                <th className="text-left  py-3 px-2 lg:px-12 font-bold">
                  Analysis Method
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="">
                <td
                  rowspan="3"
                  className="bg-green-300 bg-opacity-20 p-3 text-center"
                >
                  Microorganism
                </td>
                <td className=" text-left  py-3 px-3 bg-green-300 bg-opacity-20">
                  Total heterotrophic bacteria
                </td>
                <td className="text-left  py-3 px-2 lg:px-12 bg-green-300 bg-opacity-20">
                  Standard Method 9215
                </td>
              </tr>
              <tr className=" ">
                <td className=" text-left  py-3 px-3 bg-green-300 bg-opacity-20">
                  <i>Escherichia coli</i>
                </td>
                <td className="text-left  py-3 px-2 lg:px-12 bg-green-300 bg-opacity-20">
                  EPA method 1603
                </td>
              </tr>
              <tr className=" ">
                <td className=" text-left  py-3 px-3 bg-green-300 bg-opacity-20">
                  Enterococci
                </td>
                <td className="text-left  py-3 px-2 lg:px-12 bg-green-300 bg-opacity-20">
                  EPA method 1600
                </td>
              </tr>
              <tr className="">
                <td
                  rowspan="8"
                  className="bg-green-300 bg-opacity-20 p-3 text-center"
                >
                  Biocides
                </td>
                <td className=" text-left  py-3 px-3 bg-green-300 bg-opacity-20">
                  Chlorite
                </td>
                <td className="text-left  py-3 px-2 lg:px-12 bg-green-300 bg-opacity-20">
                  EPA Method 300.1
                </td>
              </tr>
              <tr className="">
                <td className=" text-left  py-3 px-3 bg-green-300 bg-opacity-20">
                  Chlorate
                </td>
                <td className="text-left  py-3 px-2 lg:px-12 bg-green-300 bg-opacity-20">
                  EPA Method 300.1
                </td>
              </tr>

              <tr className="">
                <td className=" text-left  py-3 px-3 bg-green-300 bg-opacity-20">
                  Halocetic Acid
                </td>
                <td className="text-left  py-3 px-2 lg:px-12 bg-green-300 bg-opacity-20">
                  EPA Method 552.2 or EPA 552.3
                </td>
              </tr>
              <tr className="">
                <td className=" text-left  py-3 px-3 bg-green-300 bg-opacity-20">
                  Total Trihalomethanes
                </td>
                <td className="text-left  py-3 px-2 lg:px-12 bg-green-300 bg-opacity-20">
                  EPA Method 8260
                </td>
              </tr>
              <tr className="">
                <td className=" text-left  py-3 px-3 bg-green-300 bg-opacity-20">
                  TRO (as Cl2)
                </td>
                <td className="text-left  py-3 px-2 lg:px-12 bg-green-300 bg-opacity-20">
                  SM 4500-Cl G
                </td>
              </tr>
              <tr className="">
                <td className=" text-left  py-3 px-3 bg-green-300 bg-opacity-20">
                  Chlorine Dioxide
                </td>
                <td className="text-left  py-3 px-2 lg:px-12 bg-green-300 bg-opacity-20">
                  Onboard Field test
                </td>
              </tr>
              <tr className="">
                <td className=" text-left  py-3 px-3 bg-green-300 bg-opacity-20">
                  Bromate
                </td>
                <td className="text-left  py-3 px-2 lg:px-12 bg-green-300 bg-opacity-20">
                  EPA Method 300.1
                </td>
              </tr>
              <tr className="">
                <td className=" text-left  py-3 px-3 bg-green-300 bg-opacity-20">
                  Bromoform
                </td>
                <td className="text-left  py-3 px-2 lg:px-12 bg-green-300 bg-opacity-20">
                  EPA Method 8260
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="mt-6">
          <IconBody img={procedure} title="Procedure">
            <Board filterArray={[]} type="vgp" />
            <p className="text-white text-justify mt-12">
              We always discuss prior to sampling with our client. Samples which
              require to be analysed immediately, Our test operators are
              conducted on board. Other samples will be sent to ISO/IEC 17025
              certified laboratories within the stipulated time for analysis.
              Results will be reported including all necessary test data to find
              out more about VGP testing services, contact us and a member of
              the team will be in touch
            </p>
          </IconBody>
        </div>
      </div>
    </div>
  );
};

export default Vgp;
